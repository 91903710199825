import React, { useState, useEffect } from 'react';
import { Button, Layout, Menu, theme, Avatar, Dropdown } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Logo from '@/assets/login/lg.png';
import useRouterParams from '@/hooks/useRouterParams';
import { useNavigate, useMatches } from 'react-router-dom';
import {useAppShallowEqualSelector, useAppDispatch} from '@/hooks/useStores';

import routes,{moudleRoutes} from '@/routes/lazyRoutes';

const { Header, Content, Sider } = Layout;

const items = [
  {
    key: '1',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="http://lunar.leanteq.cn/">
        老Lunar平台
      </a>
    ),
  },
]

const avatarItems = [
  {
    key: '1',
    label: (
      <span onClick={()=>{
        localStorage.removeItem('tokenInfo')
        // localStorage.removeItem('codes')
        window.location.href="/login"
      }}>
        退出
      </span>
    ),
  },
]


function fliterRoutes (menus:any) {
  let menuPath:any = {}
  if(!menus||menus.length===0) return []
  let routes:any = []
  moudleRoutes.map((r:any)=>{
    let hasMenu = menus.find((m:any)=>m.code  === r.key)
    if(hasMenu) {
      menuPath[r.path] = r.key
      let obj = {
        ...r,
        label: hasMenu.label||r.label
      }
      obj.children = []
      if(r.children) {
        r.children.map((c:any)=> {
          let c_hasMenu = menus.find((m:any)=>m.code  === c.key)
          if(c_hasMenu) {
            menuPath[c.path] = c.key
            obj.children.push({
              ...c,
              label: c_hasMenu.label||c.label
            })
          }
        })
      }
      routes.push(obj)
    }
  })
  // console.log('/index.tsx [72]--1','routes',routes);
  return {routes,menuPath}
}

const Index = (props:any) => {
  const navigate = useNavigate();
  const menusCodeStore = useAppShallowEqualSelector(state=>state.note.menu.menus);
  const [collapsed, setCollapsed] = useState(false);
  const [menus,setMenus] = useState<any>([])
  const {location} = useRouterParams()
  const [paths,setPaths] = useState<any>([])
  const [menuPath,setmenuPath] = useState<any>({})
  const [openMenuKey,setOpenMenuKey] = useState<any>({})
  const matches = useMatches();

  const {
    token: { colorBgContainer,colorPrimary,colorBorder,...rest },
  } = theme.useToken();
  useEffect(()=>{
      // console.log('/index.tsx [85]--1',menusCodeStore);
    if(menusCodeStore.length) {
      const {routes:menus,menuPath}:any = fliterRoutes(menusCodeStore)
      setmenuPath(menuPath)
      // console.log('/index.tsx [52]--1',menus);
      if(menus) {
        let paths:any = []
        setMenus([...menus].map((item:any)=>{
          const {key,label,icon,path,children} = item
          paths.push(path)
          return {
            key,label,icon,path,
            children:children.filter((item:any)=> !item.hide).map((c:any)=>{
              const {key,label,path} = c
              paths.push(path)
              return {
                key,
                label,
                path,
              }
            })
          }
        }))
        setPaths(paths)
      }
    }else {
      const {pathname} = location
      // console.log('/index.tsx [110]--1--pathname',pathname);
      if(pathname !== '/') {
        // navigate('/notFound')
      }
    }
  },[menusCodeStore.length])

  useEffect(()=>{
    // console.log('/index.tsx [124]--1',menus);
    if(menus.length) {
      const {pathname} = location
      if(pathname === '/') {
        let r = menus[0]?.children[0]?.path;
        r && navigate(r)
      }
    }
  },[menus])

  useEffect(()=>{
    const {pathname} = location
    // console.log('/index.tsx [110]--1',pathname,paths);
    // console.log('/index.tsx [130]--1','matches',matches);
    // console.log('/index.tsx [131]--1','menuPath',menuPath);
    if(paths.length) {
      if(matches&&matches.length===2) {
        let p_path = matches[0].pathname
        let ch_path = matches[1].pathname
        ch_path = '/'+ch_path.split('/')[1]
        // let id  = matches[1].params?.id;
        // if(id) {
        //   ch_path = ch_path.replace(`/${id}`,'')
        // }
        let openKeys = menuPath[p_path]
        let selectedKeys = [openKeys,menuPath[ch_path]]
        console.log('/index.tsx [150]--1','menuPath',ch_path,paths.includes(ch_path));
        // console.log('/index.tsx [151]--1','ch_path',paths,ch_path,id,paths.slice(1).some((p:string)=>ch_path.startsWith(p)));
        if(!paths.includes(ch_path)) {
          navigate('/notFound')
          return;
        }
  
        setOpenMenuKey({
          openKeys,
          selectedKeys
        })
      }

    }
    
  },[location,paths])

  const onOpenChange = (keys:any) => {
    const {openKeys} =  openMenuKey
    console.log('/index.tsx [162]--1','openKeys',openKeys);
    const latestOpenKey = keys.find((key:string) => openKeys?.indexOf(key) === -1);
    if (latestOpenKey) {
      setOpenMenuKey({
        ...openMenuKey,
        openKeys:latestOpenKey
      })
    } else {
      // setOpenMenuKey({

      // })
    }
    console.log('/index.tsx [162]--1',keys);
  }

  return (
    <Layout style={{height:'100%',overflow:'hidden'}}>
      <Sider 
        style={{ 
          position:'relative', 
        }}
        breakpoint="lg"
        collapsedWidth="80"
        collapsed={collapsed}
        onBreakpoint={(broken) => {
          if(broken) {
            setCollapsed(true)
          }else {
            setCollapsed(false)
          }
        }}
        onCollapse={(collapsed, type) => {
          // console.log('collapsed',collapsed, type);
        }}
      >
        <div style={{
          textAlign:'center',padding:'10px',color:'#fff',
          display:'flex',alignItems:'center',gap:10,justifyContent:'center',
          width:'100%',
          height: 80,
        }}>
          <img src={Logo} width={30}/> 
          {/* <span style={{marginTop:4}}>Lunar{`${!collapsed?'管理系统':''}`}</span> */}
          {
            !collapsed?<span style={{marginTop:4}}>Lunar管理系统</span>:''
          }
        </div>
        <div style={{
          textAlign:`${!collapsed?'right':'center'}`,
          position:'absolute',
          right:-4,
          top:'50%'
        }}>
          <Button
            type="text"
            icon={
              collapsed ? <MenuUnfoldOutlined style={{fontSize: '14px',}}/> : 
                          <MenuFoldOutlined style={{fontSize: '14px',}}/>
            }
            // block
            style={{color:'#fff'}}
            onClick={() => setCollapsed(!collapsed)}
            size="small"
          />
        </div>
        <Menu
          mode="inline"
          theme="dark"
          multiple={true}
          // defaultOpenKeys={[openMenuKey.defaultOpenKeys]}
          openKeys={[openMenuKey.openKeys]}
          selectedKeys={openMenuKey.selectedKeys}
          onOpenChange={onOpenChange}
          // selectedKeys={['social_media_search_word','social_media_search_word_'+activeKey]}
          // defaultSelectedKeys={['xhs']}
          style={{ height: '100%', borderRight: 0}}
          items={menus}
          onClick={({item}:any)=>{
            const {path} = item.props
            navigate(`${path}`)
          }}
        />
      </Sider>
    
    <Layout>
    <Header style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between', background: colorBgContainer }}>
      <div></div>
      <div>
        <Dropdown menu={{items}} >
          <span style={{marginRight:20,cursor:'pointer'}}>营销平台</span>
        </Dropdown>
        <Dropdown menu={{items:avatarItems}} >
          <Avatar size={48} icon={<UserOutlined />} />
        </Dropdown>
      </div>
    </Header>
      <Layout style={{ padding: '10px',boxSizing:'border-box' }}>
        <Content
          style={{
            // padding: 10,
            margin: 0,
            minHeight: '100%',
            overflow:'auto'
            // background: colorBgContainer,
          }}
        >
          {/* <div style={{ padding: '24px',boxSizing:'border-box' }}>Content</div> */}
          {props.children}
        </Content>
      </Layout>
    </Layout>
  </Layout>
  );
};

export default Index;