import { message } from 'antd';
// const querystring = require('querystring');
import qs from 'query-string';
import request from './request';
import {sleep} from '@/utils'

// 输出：name=John&age=30&city=New%20York

// login
// https://api-gateway-sit.alphawrite.cn/doc.html#/
// ///aw-auth/oauth2/token?grant_type=urn%3Aietf%3Aparams%3Aoauth%3Agrant-type%3Aaccount_password
// &account_name=guest01&account_password=jtx123
// &scope=alpha_scope&image_captcha=1&image_captcha_id=1’
export function token (params:any) {
  // 
  const _query = {
    // grant_type: 'urn:ietf:params:oauth:grant-type:account_password',
    grant_type: 'urn:ietf:params:oauth:grant-type:email_code',
    scope:'lunar_scope',
    // image_captcha: 1,
    // image_captcha_id:1,
    // account_name: params.user,
    // account_password: params.password,
    email: params.email,
    email_code: params.email_code
  }
  const query = qs.stringify(_query)
  return request(
    `/api/auth/oauth2/token?${query}`,
    {
    method:'POST',
  }).then((res:any)=>{
    if(res?.access_token) {
      localStorage.setItem('tokenInfo',JSON.stringify(res))
    }else {
      localStorage.setItem('tokenInfo',JSON.stringify({}))
      // message.error(res.message)
      // window.location.href = '/login'
    }
    return res
  }).catch(async (err:any)=>{
    console.log('Token===err',err)
    localStorage.setItem('tokenInfo',JSON.stringify({}))
    message.error('token获取异常')
    await sleep(1000)
    message.error('正在跳转登录页')
    await sleep(1000)
    const url = encodeURIComponent(window.location.href)
    window.location.href="/login?redirect="+url
  })
}
// https://api-gateway-sit.alphawrite.cn/aw-auth/oauth2/token
// ?refresh_token=_J4MRT5cgoTZ5kTpsSBJunOwgsEi0WqhCbuDY0lMZ4bDBvfZ5KneOlsxMy1nHUYTjM6q3HqwKNLXykrVxba0Vji61jMspOa-w7wiZyQ7SD5VI1msIg9o1lyRvne_qT7e
// &grant_type=refresh_token
let promise:any = null
export async function refreshToken () {
  const {access_token,refresh_token} = getToken()

  if(!access_token) {
    return null
  }

  if(promise) {
    return promise
  }
  // 非token接口，headers需要添加Authorization
  const params = {
    grant_type: 'refresh_token',
    refresh_token: refresh_token
  }
  const query = qs.stringify(params)
  promise = new Promise(async(resp:any,rej:any)=>{
    await request(
      `/api/auth/oauth2/token?${query}`,
      {
      method:'POST',
      __isRefresh:true
    }).then(async(res:any)=>{
      console.log('/index.ts [78]--1','res=====》',res);
      if(res?.access_token) {
        localStorage.setItem('tokenInfo',JSON.stringify(res))
        resp(res)
      }else {
        message.error('正在跳转 login page')
        await sleep(1000)
        goLogin()
        resp(null)
      }
    })
  })

  promise.finally(()=>{
    promise=null
  })
  return promise

}
const getToken = () => {
  const tokenInfo = JSON.parse(localStorage.getItem('tokenInfo')||'{}')
  return tokenInfo
}
const goLogin = () => {
  localStorage.setItem('tokenInfo',JSON.stringify({}))
  window.location.href="/login"
}

export async function emailCode  (params:any) {
  return request(`/api/auth/console/captcha/email`,{
    method: 'POST',
    body: params
  })
}

export async function menuPermisson  () {
  return request(`/api/auth/console/menu`,{
    method: 'GET',
  })
}


