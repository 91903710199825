import { message } from "antd";
import { Decimal } from 'decimal.js';
import { WHOLE_CASE_COMMAS_FIELDS, EFFECT_CASE_COMMAS_FIELDS, XHS_FIELDS, XHS_PERCENT_FIELDS } from '@/constants'

export function sleep(t:number) {
  return new Promise((res,rej)=>{
    const timer = setTimeout(() => {
      clearTimeout(timer)
      res(t)
    }, t);
  })
}

export const formatTenThousand = (num:any) => {
  if(num !==0 && !num) return 0
  if(num.toString().includes('-1')) return '无'
  if(num>10000) {
    return (num/10000).toFixed(2) + '万'
  }else {
    return num
  }
}

export const excel = (blob:Blob,name:string) =>{
  if(blob instanceof Blob) {
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${name||'数据导出'}.xlsx`

    // 触发下载
    downloadLink.click();

    // 清理下载链接
    URL.revokeObjectURL(downloadLink.href);
  }else {
    message.error('数据导出失败')
  }
}

/***
 * 
 * plus minus times dividedBy
 * 
 */
export const calcFloat = (floats:Array<number>,type="plus") => {

  let result = floats.reduce((pre:any,nex:number)=>{
    return pre[type](new Decimal(nex)||0)
  },new Decimal(floats[0]))
  // console.log('/index.ts [49]--1',result.toString());

  return result.toString()
}
// 浮点数相乘
export function calcFloatTimes (a:number,b:number) {
  return ((new Decimal(a)).times(new Decimal(b))).toString()
  // return calcFloat([a,...rest],'times')
}
// 浮点数除法
export function calcFloatDividedBy (a:number,b:number) {
  // return calcFloat([a,...rest],'dividedBy')
  return ((new Decimal(a)).dividedBy(new Decimal(b))).toString()
}
// 千分位
export function formatNumberWithCommas(m:number|string) {
  return m.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
// 将分转为元，千分位
export function formatPercentWithCommas(m:any) {
  if(m===0) return '￥ 0'
  if(!m) return '--'
  const cny = calcFloatDividedBy(m,100)
  return '￥ '+cny.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// 格式化乘100
export function formatAmountValidTimes (obj:any,filters:any=[]) {
  for(let k  in obj) {
    if (
      filters.includes(k)
    ) {
      obj[k] = calcFloatTimes(Number(obj[k]),100)
    }

    if(k === 'words') {
      let words = obj['words']||[];
      words.map((w:any)=> {
        let {percent} = w
        console.log('/index.ts [80]--1--percent',percent);
        let per = String(percent).replace('%','')
        w.percent = calcFloatTimes(Number(per),100)
      })

      obj['words'] = words
    }
  }
}
// 格式乘 100  过滤添加 whole:全案 effect：效果
// WHOLE_CASE_COMMAS_FIELDS, EFFECT_CASE_COMMAS_FIELDS 
export function formatAmountValidTimesFilter (obj:any,filter:string='whole') {
  formatAmountValidTimes(
    obj,
    filter === 'whole'?WHOLE_CASE_COMMAS_FIELDS:EFFECT_CASE_COMMAS_FIELDS
  )
}

export function xhsFormatAmountValidDividedByFilter (obj:any) {
  for(let k  in obj) {
    if (
      [...XHS_FIELDS, ...XHS_PERCENT_FIELDS].includes(k)
    ) {
      obj[k] = calcFloatDividedBy(Number(obj[k]),100)
    }else {
      obj[k] = obj[k]
    }
    
  }
}
// 格式化除 100
export function formatAmountValidDividedBy (obj:any,filters:any=[]) {
  for(let k  in obj) {
    if (
      filters.includes(k)
    ) {
      obj[k] = calcFloatDividedBy(Number(obj[k]),100)
      
    }else if(k === 'words') {
      let words = obj['words']||[];
      words.map((w:any)=> {
        let {percent} = w
        let per = calcFloatDividedBy(Number(percent),100)
        w.percent = Number(per)
      })
      console.log('/index.ts [97]--1',words);
      obj['words'] = words||[]
    }else {
      obj[k] = obj[k]
    }
  }
}

// 格式化除 100  过滤添加 whole:全案 effect：效果
// WHOLE_CASE_COMMAS_FIELDS, EFFECT_CASE_COMMAS_FIELDS 
export function formatAmountValidDividedByFilter (obj:any,filter:string='whole') {
  formatAmountValidDividedBy(
    obj,
    filter === 'whole'?WHOLE_CASE_COMMAS_FIELDS:EFFECT_CASE_COMMAS_FIELDS
  )
}

// 格式化除 100 金额千分位
export function formatReportAmountValidDividedBy (obj:any,filters:any=[]) {
  for(let k  in obj) {
    if (
      filters.includes(k)
    ) {
      obj[k] = formatNumberWithCommas(calcFloatDividedBy(Number(obj[k]),100))
    }else if(k === 'words') {
      let words = obj['words']||[];
      words.map((w:any)=> {
        let {percent} = w
        let per = calcFloatDividedBy(Number(percent),100)
        w.percent = Number(per)
        w.range = 100
      })

      obj['words'] = words||[]
    }else {
      obj[k] = obj[k]
    }
  }
}

// 格式化除 100 金额千分位 过滤添加 whole:全案 effect：效果
// WHOLE_CASE_COMMAS_FIELDS, EFFECT_CASE_COMMAS_FIELDS 
export function formatReportAmountValidDividedByFilter (obj:any,filter:string='whole') {
  formatReportAmountValidDividedBy(
    obj,
    filter === 'whole'?WHOLE_CASE_COMMAS_FIELDS:EFFECT_CASE_COMMAS_FIELDS
  )
}

function formatFieldsWords (fields:any) {
  return fields.map((item:any)=> {
    let obj:any={}
    item.map((f:any)=> {
      const {code,value} = f
      obj[code] = value
    })
    return obj
  })
}

function formatFieldsToObj (fields:any) {
  return fields.reduce((pre:any,next:any)=>{
    const {code,value} = next
    pre[code] = value
    return pre
  },{})
}

export function formatReportObj(obj:any) {
  const {fields=[] as any,...rest}:any = obj;
  let _obj = fields.reduce((pre:any,nex:any)=>{
    const {code,value,type} = nex
    if(code==='words') {
      let words = formatFieldsWords(nex['value'])
      pre['words'] = words
    }else if(type===2) {
      pre[code] = formatFieldsToObj(value)
    }
    else {
      pre[code] = value
    }
    
    return pre
  },{})

  return {..._obj,...rest}
}
function formatXHSFieldsWords (fields:any) {
  return fields.map((item:any)=> {
    let obj:any={}
    item.map((f:any)=> {
      const {code,value} = f
      obj[code] = value
    })
    return obj
  })
}
export function formatXSHReportObj(obj:any) {
  const {fields=[] as any,...rest}:any = obj;
  let _obj = fields.reduce((pre:any,nex:any)=>{
    const {code,value,type} = nex
    if(type===3) {
      let words = formatXHSFieldsWords(nex['value'])
      pre[code] = words
    }else if(type===2) {
      pre[code] = formatFieldsToObj(value)
    }
    else {
      pre[code] = value
    }
    
    return pre
  },{})

  return {..._obj,...rest}
}



