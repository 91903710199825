import React, { useEffect, useState, Suspense } from 'react';
import {RouterProvider,createBrowserRouter,} from 'react-router-dom';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routes,{moudleRoutes} from '@/routes/lazyRoutes';
// import RouterElements from '@/routes/Router';

import './App.css';


function App() {
  const [totalRoutes,setTotalRoutes] = useState<any>([...moudleRoutes,...routes])
  return (
    <RouterProvider  router={createBrowserRouter(totalRoutes)}></RouterProvider>
  );
}

export default App;
