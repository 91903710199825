import { createSlice } from '@reduxjs/toolkit';

function recursiveGetCode(data:any) {
  const menus:any = [];
  const permissions:any = [];
  function getCode(obj:any) {
    menus.push({
      code:obj.code,
      label: obj.name
    });
    if(obj.children) {
      obj.children.forEach(getCode);
    }
  }
  data.map((m:any)=>{
    getCode(m.item)
    permissions.push(m.permissions)
  })
  // getCode(data);

  return {menus,permissions};
}


type initialStateProps = {
  globalLoading: boolean,
  detail:any,
  menu:any
}

const initialState:initialStateProps =  {
  globalLoading: false,
  detail:{},
  menu:{
    menus:[],
    permissions:[]
  }
}

export const commonSlice = createSlice({
  name: 'common',
  initialState:initialState,
  reducers: {
    setGlobalLoading: (state:any, action: any) => {
      state.globalLoading = action?.payload
    },
    setNoteDetail:(state:any, action: any) => {
      state.detail = action?.payload
    },
    setPermissionMenu:(state:any, action: any) => {
      const {menus,permissions} = recursiveGetCode(action?.payload)
      // console.log('/note.ts [51]--1','menus',menus);
      state.menu = {
        // menus:['social_media_search_word', 'social_media_search_word_xhs'],
        // menus:[],
        // menus:codes.concat(['dashbord','dashbord_zhihu']),
        menus:[
          ...menus,
          {code:'crm'},
          {code:'crm_contract'},
        ],
        permissions
      }
    },
    
  }
})

export const { 
  setGlobalLoading, setNoteDetail, setPermissionMenu
} = commonSlice.actions


export default commonSlice.reducer
