import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function useLoginDetector (props?:any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [search,setSearch] = useState({})
  useEffect(()=>{
    const searchParams = new URLSearchParams(location.search)
    const allSearchParams = Object?.fromEntries(searchParams.entries());
    // console.log('===search',allSearchParams)
    if(allSearchParams) {
      setSearch(allSearchParams)
    }
    const tokenInfo = JSON.parse(localStorage.getItem('tokenInfo')||'{}')
    if(tokenInfo&&tokenInfo.access_token) {
      // console.log('/useLoginDetector.tsx [16]--1',location);
      // navigate(props?.to||'/zhihu');
    }else {
      // let url = encodeURIComponent(window.location.href)
      // navigate('/login?redirect='+url)
      navigate('/login')
    }
  },[])
  return [search]
}
