import React, { useEffect, useState } from 'react';
import {Outlet, useNavigate,useMatches} from 'react-router-dom';
import useLoginDetector from '@/hooks/useLoginDetector';
// import Layout from '@/components/Layout'
import Layout from '@/components/Layout/index';
import {useAppShallowEqualSelector, useAppDispatch} from '@/hooks/useStores';
import {setPermissionMenu} from '@/store/note';
import {menuPermisson} from '@/api'
// import Layout from '@/components/Layout/index3'

// import styles from './index.module.css'

// console.log('==qs',qs.stringify(qs.parse('1')))

function Index () {
  const dispatch = useAppDispatch()
  // const matches = useMatches();
  useLoginDetector()
  useEffect(()=>{
    // console.log('/index.tsx [19]--1',matches);
    menuPermisson().then(res=>{
      dispatch(setPermissionMenu(res.data||[]))
    }).catch(err=>{

    })
  },[])

  return <Layout>
    <Outlet />
  </Layout>
}

export default React.memo(Index)