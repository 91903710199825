import { configureStore } from '@reduxjs/toolkit';
import noteReducer from './note';

const store = configureStore({
    reducer: {
        note: noteReducer,
    }
})
    
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;