// import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// export default function useRouterParams (props?:any) {
//   const location = useLocation();
//   const [search,setSearch] = useState({})
//   useEffect(()=>{
//     const searchParams = new URLSearchParams(location.search)
//     const allSearchParams = Object?.fromEntries(searchParams.entries());
//     console.log(' ===location',location,'==allSearchParams',allSearchParams)
//     setSearch({
//       ...location,
//       search:allSearchParams,
//     })
    
//   },[location])
  
//   return search
// }

function useRouterParams() {
  const location = useLocation();
  const navigate = useNavigate();
  
  // 获取查询参数对象
  const queryParams = new URLSearchParams(location.search);
  const allSearchParams = Object?.fromEntries(queryParams.entries());
  
  
  // 设置查询参数
  const setQueryParam = (paramValue:string,paramName:string=location.pathname,) => {
    queryParams.set(paramName, paramValue);
    const newSearch = queryParams.toString();
    console.log('newSearch',newSearch)
    // navigate({ search: newSearch });
  };
  
  return {
    setQueryParam,
    location,
    query:{...allSearchParams},
  };
}

export default useRouterParams;