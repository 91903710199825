
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
export default function Index (props:any) {

  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        token: {
          colorBorder:'#DCDBDD',
          colorPrimary:'#009580',
        },
        // colorBorder:'#009580',
        components: {
          Button: {
            primaryShadow:'0 0 0',
            colorPrimary:'#009580',
            colorLink:'#009580',
            algorithm: true, // 启用算法
          },
          Input: {
            // activeBorderColor: '#009580'
          },
          Layout: {
            headerPadding: '0 20px'
          },
          Spin:{
            // contentHeight: 600
          }
        },
      }}
    >
      {props.children}
    </ConfigProvider>
  )
}