export const IMG_UPLOAD_SIZE = 1024
export const IMG_ORIGIN_SIZE = 768
export const CANVAS_WIDTH = 300
export const IMG_ITEM_WIDTH = 240
export const COLORS = [
  '#3794EAFF','#634FE3FF','#B951ECFF','#F14948FF','#FFB091FF','#FFD75EFF','#4ADFE2FF'
]
// 全案格式化 100
export const WHOLE_CASE_COMMAS_FIELDS = ['cost_amount','cost_amount_month','cost_amount_total','gmv_total']
// 效果格式化 100
export const EFFECT_CASE_COMMAS_FIELDS = [
  'roi','conversion',
  'cost','cost_total','plugin_cost',
  'cpc','cpc_avg',
  'ctr','ctr_avg',
  'cpm','cpm_avg',
]
// xhs金额格式化字段
export const XHS_FIELDS = ['account_cost','account_cpc','account_cpe','feed_cpc','feed_cpe','feed_cost','search_cost','search_cpc']
//xhs 百分比格式化
export const XHS_PERCENT_FIELDS = ['account_ctr','feed_ctr','search_ctr']

