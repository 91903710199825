import { useRoutes,Navigate, createBrowserRouter, createRoutesFromElements, Route, Routes } from "react-router-dom";
import {
  PieChartOutlined,
  SearchOutlined,
  ToolOutlined
} from '@ant-design/icons';
import Root from '@/views';

const normalRoutes = [
  {
    path: '/login',
    lazy: async () => ({Component:(await import("@/views/login")).default}),
  },
  {
    path: '/',
    // element: <Navigate to="/zhihu" /> ,
    element: <Root />,
    // lazy: async () => ({Component:(await import("@/views")).default}),
  },
  {
    path:'*',
    lazy: async () => ({Component:(await import("@/views/notFund")).default}),
  },
  {
    path:'/dataview/zhihu/:id',
    lazy: async () => ({Component:(await import("@/views/dataview/index.zh")).default}),
  },
  {
    path:'/dataview/effect/:id',
    lazy: async () => ({Component:(await import("@/views/dataview/index.zh.effect")).default}),
  },
  {
    path:'/dataview/xhs/:id',
    lazy: async () => ({Component:(await import("@/views/dataview/index.xhs")).default}),
  },
]

export const moudleRoutes = [
  {
    key:'social_media_search_word',
    path: '/',
    element: <Root />,
    icon: <SearchOutlined />,
    label:'搜索词管理',
    children:[
      {
        key:'social_media_search_word_zhihu',
        path: '/zhihu',
        label:'知乎',
        lazy: async () => ({Component:(await import("@/views/zhihu")).default}),
      },
      {
        key:'social_media_search_word_zhihu',
        path: '/zhihu/:id',
        label:'知乎详情',
        hide: true,
        lazy: async () => ({Component:(await import("@/views/zhihu/:id")).default}),
      },
      {
        key:'social_media_search_word_xhs',
        path: '/xhs',
        label:'小红书',
        lazy: async () => ({Component:(await import("@/views/xhs")).default}),
      },
      {
        key:'social_media_search_word_xhs',
        path: '/xhs/:id',
        label:'xhs详情',
        hide: true,
        lazy: async () => ({Component:(await import("@/views/xhs/:id")).default}),
      },
      {
        key:'social_media_search_word_xhs',
        path: '/xhs/comments/:id',
        label:'xhs笔记评论',
        hide: true,
        lazy: async () => ({Component:(await import("@/views/xhs/comments/:id")).default}),
      },
    ]
    
  },
  {
    key:'brand_dashboard',
    path: '/dashbord',
    element: <Root />,
    icon: <PieChartOutlined />,
    label:'品牌投放看板',
    children:[
      {
        key:'brand_dashboard_zhihu',
        path: '/dashbord/zhihu',
        label:'知乎',
        lazy: async () => ({Component:(await import("@/views/dashbord/index.zh")).default}),
      },
      {
        key:'brand_dashboard_xhs',
        path: '/dashbord/xhs',
        label:'小红书',
        lazy: async () => ({Component:(await import("@/views/dashbord/index.xhs")).default}),
      },
    ]
  },
  {
    key:'advert',
    path: '/config',
    // element: <Navigate to="/config" />,
    element: <Root />,
    icon: <PieChartOutlined />,
    label:'广告平台',
    children:[
      {
        key:'advert_jg_report',
        path: '/config/account',
        label:'小红书聚光报告',
        lazy: async () => ({Component:(await import("@/views/config")).default}),
      }
    ]
  },
  {
    key:'finance',
    path: '/crm',
    // element: <Navigate to="/config" />,
    element: <Root />,
    icon: <PieChartOutlined />,
    label:'财务11',
    children:[
      {
        key:'finance_contract',
        path: '/crm/contract',
        label:'CRM',
        lazy: async () => ({Component:(await import("@/views/crm")).default}),
      }
    ]
  },
].concat(
  [
    {
      key:'system',
      path: '/system',
      element: <Root />,
      icon: <ToolOutlined />,
      label:'系统管理',
      children:[
        {
          key:'system_mgmt_user',
          path: '/system/user',
          label:'用户管理',
          lazy: async () => ({Component:(await import("@/views/system/user")).default}),
        },
        {
          key:'system_mgmt_role',
          path: '/system/role',
          label:'角色管理',
          lazy: async () => ({Component:(await import("@/views/system/role")).default}),
        },
      ]
    },
    
  ]
)
// export default createBrowserRouter(routes);

export default normalRoutes;